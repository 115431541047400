import axios from 'axios';

// global axios config instance
const axiosWHInstance = axios.create({
    baseURL: process.env.REACT_APP_WEATHER_HISTORY_ENDPOINT, //'http://localhost:3001',
})

axiosWHInstance.defaults.headers.common['Authorization'] = 'AUTH TOKEN from instance';
axiosWHInstance.defaults.headers.post['Content-Type'] = 'application/json'

axiosWHInstance.interceptors.request.use(request=>{
    console.log(request);
    return request
}, error =>{
    console.log('axios intercept error', error);
})

export default axiosWHInstance