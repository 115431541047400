import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import globalProps from '../../../components/core/props/global-props';
import './section-nav.scss';


class Nav extends Component {
    // constructor(props) {
    //     super(props);
    // }

    // goBack = () => {
    //     this.props.history.goBack();
    //     console.log(this);
    // }

    buildNav(navArray) {
        let results = navArray.map((item,index)=>{
            return <li key={index}><NavLink exact to={item.route}>{item.label}</NavLink></li>
        })

        return results;
    }

    render() {
        const nav = this.buildNav(globalProps.nav);
        return (
            <header className="section-nav">
                <ul>
                    {nav}
                </ul>
            </header>
        );
    }
}

export default Nav;
