import React from 'react';

function IconPartlyCloudy() {
    return (
        <svg version="1.1" id="weather-partlycloudysun" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" width="30px" height="30px" viewBox="0 0 120 120" className="icon-weather">
        <g>
            <path d="M101.9,87.02c0.48,0.4,1.34,0.24,1.93-0.38c0.65-0.62,0.81-1.45,0.43-1.93c0,0-5.69-7.73-5.8-7.84
                c-0.86-0.86-2.52-0.59-3.76,0.65c-1.18,1.21-1.45,2.87-0.59,3.76C94.21,81.38,101.9,87.02,101.9,87.02z"/>
            <path d="M113.93,61.4c0.59-0.08,1.07-0.78,1.07-1.67c0-0.86-0.48-1.56-1.07-1.61c0,0-9.45-1.48-9.67-1.48c-1.18,0-2.2,1.4-2.2,3.09
                c0,1.72,1.02,3.12,2.2,3.12C104.47,62.85,113.93,61.4,113.93,61.4z"/>
            <path d="M104.26,34.78c0.38-0.46,0.21-1.32-0.43-1.93c-0.59-0.62-1.4-0.78-1.93-0.38c0,0-7.68,5.67-7.79,5.77
                c-0.86,0.86-0.59,2.53,0.59,3.76c1.24,1.18,2.9,1.48,3.76,0.62C98.56,42.49,104.26,34.78,104.26,34.78z"/>
            <path d="M51.89,32.47c-0.48-0.4-1.34-0.24-1.94,0.38c-0.64,0.62-0.81,1.48-0.37,1.93c0,0,5.64,7.71,5.75,7.84
                c0.86,0.86,2.53,0.56,3.76-0.62c1.23-1.23,1.5-2.9,0.59-3.76C59.57,38.14,51.89,32.47,51.89,32.47z"/>
            <path d="M78.48,22.78c-0.05-0.62-0.75-1.1-1.61-1.1c-0.86,0-1.61,0.48-1.67,1.1c0,0-1.45,9.45-1.45,9.61c0,1.21,1.4,2.2,3.11,2.2
                c1.72,0,3.12-0.99,3.12-2.2C79.98,32.23,78.48,22.78,78.48,22.78z"/>
            <path d="M95.99,59.73c0-10.53-8.49-19.12-19.12-19.12c-7.09,0-13.11,3.76-16.44,9.32c-4.19-2.68-9.07-4.16-14.23-4.16
                c-12.46,0-23.04,8.62-25.62,20.52C11.88,66.93,5,73.8,5,82.21c0,8.94,7.57,16.11,16.87,16.11l0,0h48.61
                c0.21,0,5.32,0.05,10.47-2.52c4.94-2.47,10.85-7.73,10.85-18.75c0-1.64-0.22-3.2-0.54-4.7C94.16,68.97,95.99,64.56,95.99,59.73z
                M78.21,89.86c-3.76,1.96-7.73,2.01-7.73,2.01l-48.61,0.05c-5.7,0-10.31-4.32-10.31-9.64c0-5.24,4.62-9.51,10.31-9.51
                c0.38,0,0.75,0.02,1.13,0.08c0,0,1.88,0.11,2.79-0.73c0.64-0.62,0.81-2.2,0.81-2.2c1.02-10.05,9.45-17.62,19.6-17.62
                c6.29,0,12.09,2.79,15.79,7.79c0.11,0.13,0.16,0.24,0.27,0.35c0.16,0.24,0.32,0.48,0.49,0.72c0.05,0.13,0.16,0.27,0.27,0.43
                c0.81,1.26,2.37,1.83,3.82,1.34c1.29-0.46,2.58-0.62,3.7-0.64c8.11,0.02,14.72,6.61,14.72,14.74
                C85.24,83.2,82.93,87.42,78.21,89.86z M70.53,55.76c-1.18,0-2.36,0.11-3.49,0.27c-0.54-0.65-1.07-1.29-1.61-1.88
                c2.04-4.22,6.34-7.12,11.44-7.12c7.09,0,12.68,5.69,12.68,12.7c0,2.04-0.43,3.92-1.29,5.64C84.44,59.57,77.94,55.76,70.53,55.76z"
                />
        </g>
        </svg>
    )
}

export default IconPartlyCloudy;