import React from 'react';
import './section-footer.scss';

function SectionFooter () {
        return (
            <footer className="section-footer">
                Brett A Lammers
          </footer>
        );
}

export default SectionFooter;
