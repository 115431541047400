import axiosWHInstance from './../../axios';

const HttpService = {};

HttpService.getData = (year) => {
    let url = '/wh/test/' + year;
    return axiosWHInstance.get(url)
}

HttpService.getWinterData = (year) => {
    let url = '/wh/winter/' + year;
    return axiosWHInstance.get(url);
}

export default HttpService;