import React from 'react';

function IconSun() {
    return (
        <svg version="1.1" id="weather-sun" xmlns="http://www.w3.org/2000/svg" x="0px"
             y="0px" width="20px" height="20px" viewBox="0 0 120 120" className="icon-weather">
        <g>
            <path d="M96.13,99.38c0.69,0.56,1.9,0.33,2.79-0.56c0.89-0.89,1.11-2.1,0.56-2.79c0,0-8.16-11.14-8.32-11.3
                c-1.25-1.25-3.68-0.85-5.42,0.9c-1.75,1.75-2.16,4.18-0.9,5.43C84.99,91.22,96.13,99.38,96.13,99.38z"/>
            <path d="M113.42,62.37c0.88-0.09,1.58-1.1,1.58-2.36c0-1.26-0.7-2.28-1.58-2.37c0,0-13.65-2.11-13.89-2.11c-1.77,0-3.2,2-3.2,4.48
                c0,2.47,1.43,4.47,3.2,4.47C99.77,64.48,113.42,62.37,113.42,62.37z"/>
            <path d="M99.48,23.97c0.56-0.69,0.33-1.9-0.56-2.79c-0.89-0.89-2.1-1.11-2.79-0.56c0,0-11.14,8.16-11.31,8.33
                c-1.25,1.25-0.85,3.68,0.9,5.43c1.74,1.75,4.17,2.15,5.42,0.9C91.31,35.11,99.48,23.97,99.48,23.97z"/>
            <path d="M59.91,32.39C44.52,32.39,32.3,44.78,32.3,60c0,15.22,12.21,27.6,27.61,27.6c15.4,0,27.61-12.38,27.61-27.6
                C87.52,44.78,75.31,32.39,59.91,32.39z M59.91,78.37c-10.26,0-18.37-8.24-18.37-18.37c0-10.13,8.1-18.37,18.37-18.37
                S78.28,49.87,78.28,60C78.28,70.13,70.17,78.37,59.91,78.37z"/>
            <path d="M23.87,20.62c-0.68-0.56-1.9-0.33-2.79,0.56c-0.89,0.89-1.11,2.1-0.56,2.79c0,0,8.17,11.14,8.33,11.31
                c1.25,1.25,3.68,0.84,5.43-0.9c1.75-1.75,2.15-4.18,0.9-5.43C35.01,28.78,23.87,20.62,23.87,20.62z"/>
            <path d="M57.7,113.39c0.09,0.88,1.11,1.58,2.37,1.58c1.26,0,2.27-0.7,2.37-1.58c0,0,2.1-13.65,2.1-13.88c0-1.76-2-3.2-4.47-3.2
                c-2.47,0-4.48,1.43-4.48,3.2C55.59,99.74,57.7,113.39,57.7,113.39z"/>
            <path d="M62.3,6.61c-0.09-0.88-1.11-1.58-2.37-1.58c-1.26,0-2.28,0.7-2.37,1.58c0,0-2.11,13.65-2.11,13.88
                c0,1.77,2.01,3.2,4.47,3.2c2.47,0,4.47-1.43,4.47-3.2C64.41,20.26,62.3,6.61,62.3,6.61z"/>
            <path d="M6.58,57.64C5.7,57.72,5,58.74,5,60c0,1.25,0.7,2.27,1.58,2.36c0,0,13.65,2.11,13.88,2.11c1.77,0,3.2-2,3.2-4.47
                c0-2.47-1.43-4.48-3.2-4.48C20.23,55.53,6.58,57.64,6.58,57.64z"/>
            <path d="M20.52,96.03c-0.56,0.69-0.33,1.9,0.56,2.79c0.89,0.89,2.1,1.12,2.79,0.56c0,0,11.14-8.16,11.31-8.33
                c1.25-1.25,0.85-3.68-0.9-5.43c-1.75-1.75-4.18-2.15-5.43-0.9C28.69,84.89,20.52,96.03,20.52,96.03z"/>
        </g>
        </svg>
    )
}

export default IconSun;