import React from 'react';

// Material
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export const YearSelect = (props) => {
    let yearsOptions = props.yearOptions.map((item, index) => {
        return <option key={index} value={item.value}>{item.label}</option>
    })
    return (
        <div className="select-year">
            <FormControl>
                <InputLabel htmlFor="age-native-simple">Select Year</InputLabel>
                <Select
                    value={props.yearSelected}
                    className="select-year"
                    native
                    onChange={props.onChange}
                >
                    <option value="" />
                    {yearsOptions}
                </Select>
            </FormControl>
        </div>
    )
}

export const YearWinterSelect = (props) => {
    let yearWinterOptions = props.yearWinterOptions.map((item, index) => {
        return <option key={index} value={item.value}>{item.label}</option>
    })
    return (
        <div className="select-winter-year">
            <FormControl>
                <InputLabel htmlFor="age-native-simple">Select Winter Year</InputLabel>
                <Select
                    value={props.yearWinterSelected}
                    className="select-winter-year"
                    native
                    onChange={props.onChange}
                >
                    <option value="" />
                    {yearWinterOptions}
                </Select>
            </FormControl>
        </div>
    )
}