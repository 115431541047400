import React, { Component } from 'react';
import globalProps from '../../../components/core/props/global-props'

import './main.scss';

export class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            years: globalProps.years,
            years_winter: globalProps.years_winter,
            year_selected: '',
            year_winter_selected: '',
            skate_hour_start: 6,
            skate_hour_end: 23,
            loading: false
        }

        this.hrs = 0;
        this.total_freeze_hours = 0;
        this.total_freeze_days = 0;
        this.total_freeze_days_concurrent = 0;
    }

    render() {
        return (
            <div className="main">
                <div>
                    <h2>title line</h2>
                    Brief intro - Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non culpa nobis distinctio vel ipsa, optio numquam at nam quod perspiciatis minus quisquam! Temporibus nisi provident dicta iste qui ipsa soluta?
                    </div>
                    <ul>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://bitbucket.org/blammers/restonice-ui/src/master/">UI repo</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://bitbucket.org/blammers/restonice-ui/src/master/">API repo</a></li>
                        <li></li>
                        <li>concrete footers for nets</li>
                        <li>Materials</li>
                    </ul>
            </div>
        )
    }
}

export default Main;
