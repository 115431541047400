import React, { Component } from 'react'
import SectionHeader from './components/core/section-header/section-header';
import SectionNav from './components/core/section-nav/section-nav';

import SectionFooter from './components/core/section-footer/section-footer';
import Main from './containers/pages/main/Main';
import Story from './containers/pages/story/Story';
import Weather from './containers/pages/weather/Weather';
import UIDesign from './containers/pages/ui-design/UI-design';

import './App.scss';
import {
  Switch,
  Route,
  BrowserRouter
} from 'react-router-dom'
// import { Route } from 'react-router-dom'

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <div className="app-icerink-ui">
          <div className="app-content-container">
            <SectionHeader />
            <SectionNav />
            <Switch>
              <Route exact path="/" component={Main}></Route>
              <Route path="/story" component={Story}></Route>
              <Route path="/ui" component={UIDesign}></Route>
              <Route path="/weather" component={Weather}></Route>
            </Switch>
          </div>
          <SectionFooter />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
