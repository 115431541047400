import React, { Component } from 'react'

import './legendWeather.scss';

class LegendWeather extends Component {

    render() {
        return (
            <div className="legend-weather">
                <ul>
                    <li className="title">Legend: </li>
                    <li><span className="key quick-freeze"></span>Quick Freeze (&lt;12&deg;)</li>
                    <li><span className="key freeze"></span>Freeze (15&deg;-32&deg;)</li>
                    <li><span className="key cold"></span>Cold (32&deg;-45&deg;)</li>
                    <li><span className="key warm"></span>Warm (45&deg;-65&deg;)</li>
                    <li><span className="key hot"></span>Hot (65&deg;-90&deg;)</li>
                    <li><span className="key very-hot"></span>Very Hot(&gt;90&deg;)</li>
                </ul>
            </div>
        )
    }
}

export default LegendWeather;
