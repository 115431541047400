import React from 'react';
import './hour-temp.scss';

function HourTemp(props) {

    let classes = "hour-temp " + props.classes;

    return (
        <div className={classes}>
            <span className="temperature">{props.temperature}&deg;</span>
        </div>
    )
}

export default HourTemp;
