import React, { Component } from 'react';
import './story.scss';

class Story extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <div className="story">
                <h2>History</h2>
                <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                    <h2>Technical</h2>
                <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Modi deleniti, sit quidem id aliquam officia est alias vel eligendi ea a 
                    quisquam illum magni facilis! Voluptatum facilis quae quaerat deleniti!</div>

                <div>
                    <h2>
                        UI/UX Design
                    </h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A</p>
                </div>

                <div>
                    <img src={require('./../../../assets/img/rink-framing.jpg')} alt="" />
                </div>

                <div>
                <img src={require('./../../../assets/img/rink-liner.jpg')} alt="" />
                </div>

                <div>
                <img src={require('./../../../assets/img/rink-ice.jpg')} alt="" />
                </div>

                <div>
                <img src={require('./../../../assets/img/rink-ice2.jpg')} alt="" />
                </div>

            </div>
        )
    }
}

export default Story;