import React, { Component, Fragment } from 'react';
import HttpService from '../../../components/services/HttpService';
import globalProps from '../../../components/core/props/global-props';
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator';
import SectionChart from '../../../components/core/section-chart/section-chart';
import SectionMenu from '../../../components/core/section-menu/section-menu';
import LegendWeather from '../../../components//legend-weather/LegendWeather';
import DayDetail from '../../../components/day-detail/day-detail';

// Material
import AcUnitIcon from '@material-ui/icons/AcUnit';

import './weather.scss';

export class Weather extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            // years: globalProps.years,
            // years_winter: globalProps.years_winter,
            yearSelected: '',
            yearWinterSelected: '',
            yearSelectedDisplay: '',
            yearSelectedTypeDisplay: '',
            skate_hour_start: 6,
            skate_hour_end: 23,
            loading: false,
            yearSelect: globalProps.prefs.yearSelect,
            legend: globalProps.prefs.legend,
            chart: globalProps.prefs.chart,
            totalFreezeHours: 0,
            totalFreezeDays: 0,
            totalFreezeDaysConcurrent: 0,
            hasData: false
        }

        this.handleLegendVisibilityToggle = this.handleLegendVisibilityToggle.bind(this);
        this.handleChartVisibilityToggle = this.handleChartVisibilityToggle.bind(this);
        this.handleYearSelectVisibilityToggle = this.handleYearSelectVisibilityToggle.bind(this);
    }

    loadData = (year) => {
        this.setState({ loading: true });
        HttpService.getData(year)
            .then(res => {
                this.setState({ loading: false, data: res.data.data, hasData: true });
                this.buildMetaObject(res.data.data);
            })
    }

    loadWinterData = (year) => {
        this.setState({ loading: true });
        HttpService.getWinterData(year)
            .then(res => {
                this.setState({ loading: false, data: res.data.data, hasData: true });
            })
    }

    onYearSelect = (event) => {
        this.hrs = 0;
        this.setState({
            yearSelected: event.target.value,
            data: [],
            yearSelectedDisplay: event.target.value,
            yearSelectedTypeDisplay: "Full year -"
        });
        this.loadData(event.target.value);
        return;
    }

    onWinterYearSelect = (event) => {
        this.hrs = 0;
        this.setState({
            yearWinterSelected: event.target.value,
            data: [],
            yearSelectedDisplay: event.target.value,
            yearSelectedTypeDisplay: "Winter months -"
        });
        this.loadWinterData(event.target.value);
        return;
    }

    buildDayDetail() {
        if (this.state.data.length > 0) {
            let content = this.state.data.map((item, index) => {
                return (
                    <DayDetail key={index} dayData={item} />
                )
            });

            return (
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h2>{this.state.yearSelectedTypeDisplay}{this.state.yearSelectedDisplay}</h2>
                        <div>
                            total_freeze_hours = {this.state.totalFreezeHours}
                        </div>
                        <div>
                            total_freeze_days = {this.state.totalFreezeDays}
                        </div>
                    </div>
                    {content}
                </div>
            )
        } else if (this.state.data.length === 0 && this.state.loading) {
            return (
                <div>
                    <h2>Loading data ...</h2>
                    <LoadingIndicator />
                </div>
            )
        }

        return (
            <h2><span style={{ color: "red", verticalAlign: "super" }}><AcUnitIcon /></span> Please select a time-frame</h2>
        )
    }

    // can remove this when meta is analyzed on api
    buildMetaObject(data) {
        let totalFreezeHours = 0;
        let totalFreezeDays = 0;
        data.forEach(item => {
            // loop through hours, count below freezing
            item.hourly.data.forEach(hourItem => {
                if (hourItem.temperature < 32) totalFreezeHours++;
            })

            // each day, if high < freezing then add to freeze days
            if (item.daily.data[0].temperatureMax < 32) totalFreezeDays++;
        });

        this.setState({ totalFreezeHours: totalFreezeHours, totalFreezeDays: totalFreezeDays });
    }

    handleLegendVisibilityToggle() {
        this.setState({ legend: !this.state.legend });
    }

    handleChartVisibilityToggle() {
        this.setState({ chart: !this.state.chart });
    }

    handleYearSelectVisibilityToggle() {
        this.setState({ yearSelect: !this.state.yearSelect });
    }

    render() {
        const content = this.buildDayDetail();

        return (
            <Fragment>
                <button onClick={this.handleYearSelectVisibilityToggle}>Select year</button>
                <button onClick={this.handleLegendVisibilityToggle}>Legend</button>
                <button onClick={this.handleChartVisibilityToggle}>Chart</button>

                {/* year select */}
                {this.state.yearSelect ?
                    <div>
                        <SectionMenu
                            onYearSelect={this.onYearSelect}
                            onYearWinterSelect={this.onWinterYearSelect}
                            yearWinterSelected={this.state.yearWinterSelected}
                            yearSelected={this.state.yearSelected}
                            showIcon={this.state.hasData}
                        />
                    </div> : null}

                {/* legend */}
                {this.state.legend ?
                    <div>
                        <LegendWeather />
                    </div> : null}

                {/* chart */}
                {this.state.chart ?
                    <div>
                        <SectionChart />
                    </div> : null}

                {/* content */}
                <div className="content">
                    {content}
                </div>
            </Fragment>
        );
    }
}

export default Weather;
