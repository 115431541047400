import React from 'react';

function IconSleet() {
    return (
<svg version="1.1" id="weather-sleet" xmlns="http://www.w3.org/2000/svg" x="0px"
	 y="0px" width="120px" height="120px" viewBox="0 0 120 120" className="icon-weather">
<g>
	<g>
		<path d="M50.445,88.978c0-2.894-2.357-5.25-5.25-5.25c-2.906,0-5.25,2.356-5.25,5.25c0,2.906,2.345,5.25,5.25,5.25
			C48.088,94.228,50.445,91.884,50.445,88.978z"/>
		<path d="M46.472,71.364L46.472,71.364c-0.258-0.43-0.731-0.731-1.291-0.731c-0.545,0-1.019,0.302-1.277,0.731l0,0l-2.208,4.118
			c0,1.937,1.563,3.5,3.5,3.5c1.936,0,3.5-1.563,3.5-3.5L46.472,71.364z"/>
	</g>
	<g>
		<circle cx="77.879" cy="88.978" r="5.25"/>
		<path d="M79.185,71.364L79.185,71.364c-0.272-0.43-0.731-0.731-1.292-0.731c-0.543,0-1.017,0.302-1.274,0.731h-0.016l-2.224,4.118
			c0,1.937,1.563,3.5,3.5,3.5c1.938,0,3.5-1.563,3.5-3.5L79.185,71.364z"/>
	</g>
	<path d="M66.787,101.803c0-2.894-2.346-5.25-5.25-5.25c-2.893,0-5.25,2.345-5.25,5.25s2.345,5.25,5.25,5.25
		C64.441,107.053,66.787,104.696,66.787,101.803z"/>
	<path d="M62.829,71.364L62.829,71.364c-0.258-0.431-0.732-0.731-1.292-0.731c-0.545,0-1.019,0.301-1.277,0.731l0,0l-2.223,4.118
		c0,1.937,1.563,3.5,3.5,3.5s3.5-1.563,3.5-3.5L62.829,71.364z"/>
	<path d="M62.829,84.869L62.829,84.869c-0.258-0.445-0.732-0.746-1.292-0.746c-0.545,0-1.019,0.301-1.277,0.746l0,0l-2.223,4.119
		c0,1.936,1.563,3.498,3.5,3.498s3.5-1.563,3.5-3.498L62.829,84.869z"/>
	<path d="M88.029,25.627c-1.479,0-2.949,0.11-4.399,0.35c-6.29-8.2-15.96-13.03-26.41-13.03c-15.8,0-29.17,10.93-32.5,26.01
		C13.7,39.777,5,48.498,5,59.158c0,11.32,9.6,20.43,21.39,20.43l0,0h4.14v-8.11l-4.15,0.01c-7.22-0.01-13.09-5.489-13.09-12.24
		c0-6.63,5.88-12.03,13.12-12.03c0.47,0,0.95,0.02,1.42,0.07c0,0,2.39,0.14,3.51-0.89c0.87-0.8,1.02-2.8,1.02-2.8
		c1.35-12.74,12.04-22.35,24.87-22.35c8,0,15.331,3.56,20.03,9.9c0.1,0.14,0.211,0.29,0.311,0.43c0.209,0.3,0.42,0.6,0.63,0.91
		c0.12,0.18,0.239,0.36,0.35,0.54c1.021,1.6,3.011,2.3,4.81,1.69c1.65-0.56,3.281-0.76,4.75-0.81
		c10.271,0.03,18.621,8.41,18.621,18.69c0,7.82-2.92,13.14-8.92,16.25c-1.75,0.9-3.531,1.49-5.08,1.859v8.42
		c2.41-0.42,5.49-1.219,8.569-2.77c6.24-3.12,13.7-9.79,13.7-23.75C115,37.728,102.9,25.627,88.029,25.627z"/>
</g>
</svg>
    )
}

export default IconSleet;