import React, { Component, Fragment } from 'react';
import HourTemp from './../hour-temp/hour-temp';
import WeatherIcon from './../weather-icon/weather-icon';
import './day-detail.scss';

class DayDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.parseProps();
        // console.log(this.props);
    }

    parseProps() {
        const freezeHours = this.getFreezeHours(this.props.dayData.hourly.data);
        const freezeDay = freezeHours === 24 ? true : false;
        const dayAvergeTemperature = (this.props.dayData.daily.data[0].temperatureLow + this.props.dayData.daily.data[0].temperatureHigh) / 2;

        this.setState({
            data: this.props.dayData,
            date: this.formattedDate(this.props.dayData.daily.data[0].time),
            weatherIcon: this.props.dayData.daily.data[0].icon,
            weatherSummary: this.props.dayData.daily.data[0].summary,
            dayLowTemperature: this.props.dayData.daily.data[0].temperatureLow,
            dayHighTemperature: this.props.dayData.daily.data[0].temperatureHigh,
            dayAvergeTemperature: dayAvergeTemperature.toFixed(2),
            dayClassification: this.getHourTempClass(dayAvergeTemperature),
            hourlyWeather: this.buildHourlyData(this.props.dayData.hourly.data),
            freezeHours: freezeHours,
            freezeDay: freezeDay
        });
    }

    formattedDate(timestamp) {
        let date = new Date(timestamp * 1000);
        let month = date.getMonth() + 1;
        let day = date.getDay() + 1;
        let year = date.getFullYear();

        return month + "/" + day + "/" + year;
    }

    ////////

    buildHourlyData(data) {
        let results = data.map((item, index) => {
            let temp = item.temperature ? item.temperature.toString().substring(0, 2) : "xx";
            let cln = this.getHourTempClass(temp);
            return <HourTemp key={index} temperature={temp} classes={cln} />
        });

        return results;
    }

    getHourTempClass(temp) {
        if (temp <= 15) {
            return "quick-freeze";
        } else if (temp > 15 && temp <= 32) {
            return "freeze";
        } else if (temp > 32 && temp <= 45) {
            return "cold";
        } else if (temp > 45 && temp < 65) {
            return "warm";
        } else if (temp >= 65 && temp < 90) {
            return "hot";
        } else if (temp >= 90) {
            return "very-hot";
        }
    }

    getFreezeHours(data) {
        let hours = 0;
        if (data) {
            data.forEach(item => {
                if (item.temperature < 32) hours++;
            });
        }

        return hours;
    }

    buildDayClass() {
        let cln = ["day-detail"];
        if (this.state.freezeDay) cln.push("freeze-day");
        cln.push(this.getHourTempClass(this.state.dayAvergeTemperature));
        return cln.join(" ")
    }

    render() {
        const dayClasses = this.buildDayClass();
        return (
            <div className={dayClasses}>
                <Fragment>
                    <div className="daily-summary">
                        <div style={{ marginRight: "10px" }}>
                            <div>{this.state.date}</div>
                            <div>
                                {"L: " + this.state.dayLowTemperature + " "}
                                {"H: " + this.state.dayHighTemperature + " "}
                                {"Avg: " + this.state.dayAvergeTemperature}
                                {this.state.freezeHours > 0 ? <div>freeze hours: {this.state.freezeHours}</div> : ""}
                            </div>
                        </div>
                        
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <WeatherIcon weathericon={this.state.weatherIcon} />
                            <span className="weather-summary">{this.state.weatherSummary}</span>
                        </div>
                    </div>
                    <div className="hourly-content">
                        {this.state.hourlyWeather}
                    </div>
                </Fragment>
            </div>
        )
    }

}

export default DayDetail;
