import React from 'react';
import './section-chart.scss';

function SectionChart(props) {
    return (
        <div className="section-chart">
            <h2>section chart</h2>
            <div>
                total_freeze_hours = x
            </div>
            <div>
                total_freeze_days = y
            </div>
            <div>
                total_freeze_days_concurrent = z
            </div>
        </div>
    )

}

export default SectionChart;