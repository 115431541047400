import React from 'react';
import './section-header.scss';

function Header() {

        return (
                <div className="section-header">
                        Reston Ice
                </div>
        );
}

export default Header;
