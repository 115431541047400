import React, { Component } from 'react'
import './ui-design.scss';

class UIDesign extends Component {
    render() {
        return (
            <div className="ui-design">
                <div>
                    <h2>
                        UI/UX Design
                    </h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur odit perspiciatis nobis repudiandae cumque hic facere accusantium, corrupti odio quia quod quisquam, cupiditate, neque officiis sed dolores debitis labore autem!</p>
                </div>

                <div>
                    <img src={require('./../../../assets/img/scratch-board.png')} alt="" />
                </div>

                <div>
                    <img src={require('./../../../assets/img/Mobile.png')} alt="" />
                </div>

                <div>
                    <img src={require('./../../../assets/img/Tablet.png')} alt="" />
                </div>

                <div>
                    <img src={require('./../../../assets/img/Desktop.png')} alt="" />
                </div>

            </div>
        )
    }
}

export default UIDesign;
