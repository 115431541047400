import React, { Component, Fragment } from 'react';
import { YearSelect, YearWinterSelect } from '../../../components/YearSelect'
import globalProps from '../../../components/core/props/global-props';

// Material
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AcUnitIcon from '@material-ui/icons/AcUnit';

import './section-menu.scss';

export class SectionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yearSelectionType: "year",
            years: globalProps.years,
            years_winter: globalProps.years_winter
        }

        this.onSelectYearSelectionTypeChange = this.onSelectYearSelectionTypeChange.bind(this);
    }

    onSelectYearSelectionTypeChange(event) {
        this.setState({ yearSelectionType: event.target.value })
    }

    render() {
        return (
            <Fragment>
                <div className="section-menu">
                    <div style={{
                        flexDirection: "row",
                        display: "flex"
                    }}>
                        {!this.props.showIcon ? <div style={{color: "red"}}><AcUnitIcon /></div> : null}
                        <div className="year-selection-type">
                            <RadioGroup defaultValue={this.state.yearSelectionType}
                                aria-label="gender"
                                name="customized-radios"
                                style={{ flexDirection: "row" }}
                                onChange={this.onSelectYearSelectionTypeChange}>
                                <FormControlLabel value="year" control={<Radio />} label="All year"></FormControlLabel>
                                <FormControlLabel value="winter" control={<Radio />} label="Winter months"></FormControlLabel>
                            </RadioGroup>
                        </div>
                        <div>
                            {this.state.yearSelectionType === "year" ?
                                <YearSelect
                                    yearSelected={this.props.yearSelected}
                                    onChange={this.props.onYearSelect}
                                    yearOptions={this.state.years} /> :
                                <YearWinterSelect
                                    yearWinterSelected={this.props.yearWinterSelected}
                                    onChange={this.props.onYearWinterSelect}
                                    yearWinterOptions={this.state.years_winter} />
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default SectionMenu;