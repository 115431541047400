import React from 'react';
// import CircularProgress from '@material-ui/core/CircularProgress';
import './loading-indicator.scss'

const LoadingIndicator = (props) => {
    return (
        <div className="loading-indicator">
            <div className="loader"></div>
        </div>
    )
}

export default LoadingIndicator;