import React from 'react';
import IconRain from './icon-rain';
import IconSun from './icon-sun';
import IconPartlyCloudy from './icon-partly-cloudy';
import IconSnow from './icon-snow';
import IconSleet from './icon-sleet';
import IconCloudy from './icon-cloudy';
import './weather-icon.scss';

function WeatherIcon(props) {
    switch (props.weathericon) {
        case "rain":
            return <span style={{ marginRight: "12px" }}><IconRain /></span>

        case "clear-day":
            return <span style={{ marginRight: "12px" }}><IconSun /></span>

        case "partly-cloudy-day":
            return <span style={{ marginRight: "12px" }}><IconPartlyCloudy /></span>

        case "snow":
            return <span style={{ marginRight: "12px" }}><IconSnow /></span>

        case "sleet":
            return <span style={{ marginRight: "12px" }}><IconSleet /></span>

        case "cloudy":
            return <span style={{ marginRight: "12px" }}><IconCloudy /></span>

        default:
            return <span style={{ marginRight: "12px" }}>xxx</span>
    }
}

export default WeatherIcon;