import React from 'react';

function IconRain() {
    return (
        <svg version="1.1" id="weather-snow" xmlns="http://www.w3.org/2000/svg" x="0px"
            y="0px" width="20px" height="20px" viewBox="0 0 120 120" className="icon-weather">
        <g>
            <g>
                <path d="M67.133,75.314c0-2.904-2.347-5.248-5.25-5.248c-2.893,0-5.25,2.344-5.25,5.248c0,2.893,2.344,5.25,5.25,5.25
                    C64.786,80.564,67.133,78.207,67.133,75.314z"/>
                <path d="M66.742,102.865c0-2.904-2.356-5.25-5.248-5.25c-2.894,0-5.251,2.346-5.251,5.25s2.358,5.25,5.251,5.25
                    C64.386,108.115,66.742,105.758,66.742,102.865z"/>
                <path d="M66.742,89.086c0-2.906-2.356-5.252-5.248-5.252c-2.894,0-5.251,2.359-5.251,5.252c0,2.904,2.358,5.248,5.251,5.248
                    C64.386,94.334,66.742,91.99,66.742,89.086z"/>
            </g>
            <g>
                <path d="M50.845,75.314c0-2.904-2.358-5.25-5.25-5.25c-2.905,0-5.25,2.346-5.25,5.25c0,2.895,2.358,5.25,5.25,5.25
                    S50.845,78.209,50.845,75.314z"/>
                <circle cx="45.195" cy="89.084" r="5.25"/>
            </g>
            <g>
                <path d="M83.23,75.314c0-2.906-2.345-5.25-5.25-5.25c-2.904,0-5.25,2.344-5.25,5.25c0,2.895,2.346,5.252,5.25,5.252
                    C80.886,80.566,83.23,78.209,83.23,75.314z"/>
                <circle cx="77.98" cy="89.084" r="5.25"/>
            </g>
            <path d="M88.029,24.564c-1.479,0-2.949,0.11-4.399,0.351c-6.29-8.2-15.96-13.03-26.41-13.03c-15.8,0-29.17,10.93-32.51,26.01
                C13.7,38.715,5,47.435,5,58.095c0,11.312,9.59,20.421,21.39,20.421l0,0h4.14v-8.109l-4.15,0.01c-7.22,0-13.1-5.49-13.1-12.23
                c0-6.63,5.89-12.029,13.12-12.029c0.48,0,0.95,0.02,1.42,0.069c0,0,2.39,0.141,3.51-0.89c0.87-0.811,1.02-2.8,1.02-2.8
                c1.35-12.74,12.03-22.351,24.87-22.351c8,0,15.33,3.55,20.03,9.9c0.1,0.14,0.2,0.29,0.311,0.43c0.209,0.3,0.42,0.601,0.619,0.91
                c0.12,0.18,0.24,0.36,0.36,0.54c1.021,1.6,3,2.3,4.81,1.69c1.65-0.561,3.271-0.761,4.75-0.811
                c10.271,0.03,18.621,8.41,18.621,18.69c0,7.819-2.921,13.14-8.921,16.251c-1.75,0.91-3.53,1.488-5.079,1.859v8.42
                c2.42-0.42,5.489-1.221,8.569-2.76c6.26-3.141,13.71-9.811,13.71-23.771C115,36.665,102.9,24.564,88.029,24.564z"/>
        </g>
        </svg>
    )
}

export default IconRain;