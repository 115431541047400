const globalProps = {

    years: [
        { label: "2000", value: 2000 },
        { label: "2001", value: 2001 },
        { label: "2002", value: 2002 },
        { label: "2005", value: 2005 },
        { label: "2009", value: 2009 },
        { label: "2010", value: 2010 },
        { label: "2011", value: 2011 },
        { label: "2012", value: 2012 },
        { label: "2013", value: 2013 },
        { label: "2014", value: 2014 },
        { label: "2015", value: 2015 },
        { label: "2016", value: 2016 },
        { label: "2017", value: 2017 },
        { label: "2018", value: 2018 },
        { label: "2019", value: 2019 }
    ],
    years_winter: [
        { label: "2000-2001", value: 2000 },
        { label: "2001-2002", value: 2001 },
        { label: "2002-2003", value: 2002 },
        { label: "2005-2006", value: 2005 },
        { label: "2009-2010", value: 2009 },
        { label: "2010-2011", value: 2010 },
        { label: "2014-2015", value: 2014 },
        { label: "2015-2016", value: 2015 },
        { label: "2016-2017", value: 2016 },
        { label: "2017-2018", value: 2017 },
        { label: "2018-2019", value: 2018 },
        { label: "2019-2020", value: 2019 }
    ],
    nav: [
        {label: "Home", route: "/"},
        {label: "Weather History", route: "/weather"},
        {label: "Story", route: "/story"},
        {label: "UI/Design", route: "/ui"},
    ],
    prefs: {
        yearSelect: true,
        legend: false,
        chart: false
    }
};

export default globalProps;