import React from 'react';

function IconCloudy() {
    return (
        <svg version="1.1" id="weather-cloudy" xmlns="http://www.w3.org/2000/svg" x="0px"
            y="0px" width="120px" height="120px" viewBox="0 0 120 120" className="icon-weather">
        <path d="M110.02,42.81c-3.44-3.56-8.24-5.6-13.18-5.6c-0.81,0-1.62,0.06-2.42,0.16c-4.19-5.2-10.49-8.25-17.27-8.25
            c-6.05,0-11.66,2.58-15.67,6.54c0,0-0.01-0.03,0,0c-3.53-1.52-7.39-2.34-11.38-2.34c-13.65,0-25.2,9.44-28.08,22.47
            C12.51,56.49,5,64.03,5,73.24c0,9.77,8.28,17.64,18.47,17.64h0l53.21,0c0.24,0,5.85,0.05,11.48-2.78
            c4.37-2.19,9.41-6.38,11.19-14.26c6.5-0.89,15.21-5.42,15.63-18.31C115.15,50.8,113.38,46.28,110.02,42.81z M85.16,81.61
            c-4.13,2.14-8.44,2.19-8.47,2.2l-53.22,0.08c-6.24,0-11.31-4.74-11.31-10.57c0-5.73,5.08-10.39,11.33-10.39
            c0.41,0,0.82,0.02,1.22,0.06c0,0,2.06,0.13,3.03-0.77c0.75-0.7,0.88-2.42,0.88-2.42c1.16-11.01,10.4-19.31,21.48-19.31
            c6.91,0,13.24,3.07,17.31,8.55c0.09,0.12,0.17,0.25,0.26,0.37c0.18,0.26,0.37,0.52,0.54,0.79c0.1,0.15,0.2,0.31,0.31,0.46
            c0.88,1.38,2.6,1.99,4.15,1.46c1.43-0.48,2.83-0.66,4.1-0.7c8.87,0.03,16.08,7.26,16.08,16.14C92.86,74.33,90.34,78.93,85.16,81.61z
            M107.86,55.3c-0.26,7.72-4.65,10.3-7.87,11.16c-0.58-12.33-10.79-22.2-23.26-22.2c-1.28,0-2.55,0.1-3.8,0.3
            c-1.48-1.93-3.18-3.64-5.04-5.11c0.01,0,0.02-0.01,0.03-0.02c-0.01,0-0.01-0.01-0.01-0.01c2.56-2,5.78-3.18,9.26-3.18
            c5.18,0,9.93,2.61,12.71,6.98c0.85,1.33,2.46,1.95,3.98,1.52c0.97-0.28,1.98-0.41,3-0.41c3.01,0,5.95,1.25,8.05,3.42
            C106.9,49.84,107.96,52.51,107.86,55.3z"/>
        </svg>
    )
}

export default IconCloudy;